<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="username">User Name</vs-th>
        <vs-th sort-key="vendor_code">Vendor Code</vs-th>
        <vs-th sort-key="vendor_name">Vendor Name</vs-th>
        <vs-th sort-key="po_no">PO No</vs-th>
        <vs-th sort-key="gr_no">GR No</vs-th>
        <vs-th sort-key="sj_no">SJ No</vs-th>
        <vs-th sort-key="vendor_bill_no">Vendor Bill No</vs-th>
        <vs-th sort-key="vendor_invoice_no">Vendor Invoice No</vs-th>
        <vs-th sort-key="faktur_pajak_no">Faktur Pajak No</vs-th>
        <vs-th sort-key="document_type">Document Type</vs-th>
        <vs-th sort-key="document_date">Document Date</vs-th>
        <vs-th sort-key="fiscal_year">Fiscal Year</vs-th>
        <vs-th sort-key="entry_date">Entry Date</vs-th>
        <vs-th sort-key="posting_date">Posting Date</vs-th>
        <vs-th sort-key="net_due_date">Net Due Date</vs-th>
        <vs-th sort-key="time_of_entry">Time Of Entry</vs-th>
        <vs-th sort-key="business_area">Business Area</vs-th>
        <vs-th sort-key="amt_in_local_currency">Amt In Local Currency</vs-th>
        <vs-th sort-key="local_currency">Local Currency</vs-th>
        <vs-th sort-key="glaccount">GLAccount</vs-th>
        <vs-th sort-key="company_code">Company Code</vs-th>
        <vs-th sort-key="cost_center">Cost Center</vs-th>
        <vs-th sort-key="year_month">Year Month</vs-th>
        <vs-th sort-key="discount_amt">Discount Amt</vs-th>
        <vs-th sort-key="posting_period">Posting Period</vs-th>
        <vs-th sort-key="reversed_doc_no">Reversed Doc No</vs-th>
        <vs-th sort-key="reversal_reason">Reversal Reason</vs-th>
        <vs-th sort-key="reversal_indicator">Reversal Indicator</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].username">
            {{ data[indextr].username }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_code">
            {{ data[indextr].vendor_code }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_name">
            {{ data[indextr].vendor_name }}
          </vs-td>
          <vs-td :data="data[indextr].po_no"> {{ data[indextr].po_no }} </vs-td>
          <vs-td :data="data[indextr].gr_no"> {{ data[indextr].gr_no }} </vs-td>
          <vs-td :data="data[indextr].sj_no"> {{ data[indextr].sj_no }} </vs-td>
          <vs-td :data="data[indextr].vendor_bill_no">
            {{ data[indextr].vendor_bill_no }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_invoice_no">
            {{ data[indextr].vendor_invoice_no }}
          </vs-td>
          <vs-td :data="data[indextr].faktur_pajak_no">
            {{ data[indextr].faktur_pajak_no }}
          </vs-td>
          <vs-td :data="data[indextr].document_type">
            {{ data[indextr].document_type }}
          </vs-td>
          <vs-td :data="data[indextr].document_date">
            {{ data[indextr].document_date }}
          </vs-td>
          <vs-td :data="data[indextr].fiscal_year">
            {{ data[indextr].fiscal_year }}
          </vs-td>
          <vs-td :data="data[indextr].entry_date">
            {{ data[indextr].entry_date }}
          </vs-td>
          <vs-td :data="data[indextr].posting_date">
            {{ data[indextr].posting_date }}
          </vs-td>
          <vs-td :data="data[indextr].net_due_date">
            {{ data[indextr].net_due_date }}
          </vs-td>
          <vs-td :data="data[indextr].time_of_entry">
            {{ data[indextr].time_of_entry }}
          </vs-td>
          <vs-td :data="data[indextr].business_area">
            {{ data[indextr].business_area }}
          </vs-td>
          <vs-td :data="data[indextr].amt_in_local_currency">
            {{ formatPrice(data[indextr].amt_in_local_currency) }}
          </vs-td>
          <vs-td :data="data[indextr].local_currency">
            {{ data[indextr].local_currency }}
          </vs-td>
          <vs-td :data="data[indextr].glaccount">
            {{ data[indextr].glaccount }}
          </vs-td>
          <vs-td :data="data[indextr].company_code">
            {{ data[indextr].company_code }}
          </vs-td>
          <vs-td :data="data[indextr].cost_center">
            {{ data[indextr].cost_center }}
          </vs-td>
          <vs-td :data="data[indextr].year_month">
            {{ data[indextr].year_month }}
          </vs-td>
          <vs-td :data="data[indextr].discount_amt">
            {{ formatPrice(data[indextr].discount_amt) }}
          </vs-td>
          <vs-td :data="data[indextr].posting_period">
            {{ data[indextr].posting_period }}
          </vs-td>
          <vs-td :data="data[indextr].reversed_doc_no">
            {{ data[indextr].reversed_doc_no }}
          </vs-td>
          <vs-td :data="data[indextr].reversal_reason">
            {{ data[indextr].reversal_reason }}
          </vs-td>
          <vs-td :data="data[indextr].reversal_indicator">
            {{ data[indextr].reversal_indicator }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startInvoiceDate: {
      type: Date,
    },
    endInvoiceDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    vendorIDs: {
      type: Array,
    },
    vendorNames: {
      type: Array,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportAP.tableData,
      total: (state) => state.reportAP.total,
      totalPerPage: (state) => state.reportAP.totalPerPage,
      totalPage: (state) => state.reportAP.totalPage,
      totalSearch: (state) => state.reportAP.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportAP/getAPReport',
      generateAPReport: 'reportAP/generateAPReport'
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'username',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        start_invoice_date:
          this.startInvoiceDate == null
            ? undefined
            : moment(this.startInvoiceDate).format('YYYY-MM-DD'),
        end_invoice_date:
          this.endInvoiceDate == null
            ? undefined
            : moment(this.endInvoiceDate).format('YYYY-MM-DD'),
        start_due_date:
          this.startDueDate == null
            ? undefined
            : moment(this.startDueDate).format('YYYY-MM-DD'),
        end_due_date:
          this.endDueDate == null
            ? undefined
            : moment(this.endDueDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      this.generateAPReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        start_invoice_date:
          this.startInvoiceDate == null
            ? undefined
            : moment(this.startInvoiceDate).format('YYYY-MM-DD'),
        end_invoice_date:
          this.endInvoiceDate == null
            ? undefined
            : moment(this.endInvoiceDate).format('YYYY-MM-DD'),
        start_due_date:
          this.startDueDate == null
            ? undefined
            : moment(this.startDueDate).format('YYYY-MM-DD'),
        end_due_date:
          this.endDueDate == null
            ? undefined
            : moment(this.endDueDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
      }).then((resp) => {
        this.$vs.notify({
          color: 'success',
          title: 'Processing',
          text: resp.message,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-x-circle',
        });
        this.$vs.loading.close();
      })
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
